/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'
import { type CTAVideo, CTAVideoSchema } from './video'

/* TypeScript */
export type ContentProps = {
  technicalName?: string
  title: string
  text: string
  subtitle?: string
  ctaLink?: CTALink
  ctaVideo?: CTAVideo
  // TODO: reuse common tracking type
  tracking?: Record<string, string>
}

/* Schemas */
export const ContentPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string(),
  text: z.string(),
  subtitle: z.string().optional(),
  ctaLink: CTALinkSchema.optional(),
  ctaVideo: CTAVideoSchema.optional(),
  tracking: z.record(z.string()).optional(),
})

/* Schemas validation */
type TestContentProps = ExpectTrue<
  Equal<ContentProps, z.infer<typeof ContentPropsSchema>>
>
