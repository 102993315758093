export default {
  contextTitle: {
    id: 'cms_context_title',
    defaultMessage: 'CMS Information',
  },
  contextLastCacheUpdate: {
    id: 'cms_context_cache_update',
    defaultMessage: 'Last cache update: ',
  },
  contextDocumentation: {
    id: 'cms_context_documentation',
    defaultMessage: 'Documentation',
  },
  contextOpenCMSPage: {
    id: 'cms_context_open_cms_page',
    defaultMessage: 'Open page in CMS',
  },
  expandContextErrors: {
    id: 'cms_context_errors_expand',
    defaultMessage: 'Expand to see CMS errors',
  },
  collapseContextErrors: {
    id: 'cms_context_errors_collapse',
    defaultMessage: 'Collapse CMS errors',
  },
  contextTableName: {
    id: 'cms_context_table_name',
    defaultMessage: 'Block Name',
  },
  contextTableError: {
    id: 'cms_context_table_error',
    defaultMessage: 'Error message',
  },
  contextTableInfo: {
    id: 'cms_context_table_info',
    defaultMessage: 'Block Info',
  },
  contextTableActions: {
    id: 'cms_context_table_actions',
    defaultMessage: 'Actions',
  },
  contextTableScroll: {
    id: 'cms_context_table_scroll',
    defaultMessage: 'Scroll',
  },
  contextTableOpen: {
    id: 'cms_context_table_open',
    defaultMessage: 'Open',
  },
  contextTableBlockId: {
    id: 'cms_context_table_block_id',
    defaultMessage: 'Block ID: {blockId}',
  },
  contextTableEntryId: {
    id: 'cms_context_table_entry_id',
    defaultMessage: 'Entry ID: {entryId}',
  },
} as const
