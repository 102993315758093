/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import {
  LooseRichTextSchema,
  type LoosenRichTextFields,
} from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type RichText } from './rich-text'

/* TypeScript */
export type RichTextProps = {
  technicalName?: string
  richText: RichText | Record<string, never>
  lightText?: boolean
  ignoreLinks?: boolean
  defaultStyle?: boolean
}

/* Schemas */
export const RichTextPropsSchema = z.object({
  technicalName: z.string().optional(),
  richText: LooseRichTextSchema,
  lightText: z.boolean().optional(),
  ignoreLinks: z.boolean().optional(),
  defaultStyle: z.boolean().optional(),
})

/* Schemas validation */
type TestRichTextProps = ExpectTrue<
  Equal<
    LoosenRichTextFields<RichTextProps>,
    z.infer<typeof RichTextPropsSchema>
  >
>
