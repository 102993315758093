/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CmsButtonProps, CmsButtonPropsSchema } from './cms-button'

/* TypeScript */
export type AnimatedProductCheckpointsProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  categories: Array<{
    id: string
    name: 'laptops' | 'consoles' | 'tablets' | 'smartwatches' | 'smartphones'
  }>
  button?: CmsButtonProps
}

/* Schemas */
export const AnimatedProductCheckpointsPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  categories: z.array(
    z.object({
      id: z.string(),
      name: z.enum([
        'laptops',
        'consoles',
        'tablets',
        'smartwatches',
        'smartphones',
      ]),
    }),
  ),
  button: CmsButtonPropsSchema.optional(),
})

/* Schemas validation */
type TestAnimatedProductCheckpointsProps = ExpectTrue<
  Equal<
    AnimatedProductCheckpointsProps,
    z.infer<typeof AnimatedProductCheckpointsPropsSchema>
  >
>
