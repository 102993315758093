/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type Image, ImageSchema } from './image'
import { type VideoAssetFile, VideoAssetFileSchema } from './video'

/* TypeScript */
export type VideoContent = {
  image?: Image
  video?: {
    title: string
    description?: string
    file: VideoAssetFile
  }
  id: string
}

export type VideosBlockProps = {
  technicalName?: string
  subtitle?: string
  title?: string
  videos: VideoContent[]
}

/* Schemas */
export const VideoContentSchema = z.object({
  image: ImageSchema.optional(),
  video: z
    .object({
      title: z.string(),
      description: z.string().optional(),
      file: VideoAssetFileSchema,
    })
    .optional(),
  id: z.string(),
})

export const VideosBlockPropsSchema = z.object({
  technicalName: z.string().optional(),
  subtitle: z.string().optional(),
  title: z.string().optional(),
  videos: z.array(VideoContentSchema),
})

/* Schemas validation */
type TestVideosBlockProps = ExpectTrue<
  Equal<VideosBlockProps, z.infer<typeof VideosBlockPropsSchema>>
>
