/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import {
  type BasePageContentAPI,
  type MappedApiPageContent,
} from './base-page-content'
import { type ContentLink, ContentLinkSchema } from './content-link'
import { type Device } from './device'
import { type Image, ImageSchema, type SimpleImage } from './image'

/* TypeScript */
export type ApiArticlePageContent = BasePageContentAPI & {
  summary: string
  categories: Category[]
  author?: Author
  relatedArticles: RelatedArticles | null
  updateDate: string
  wordCount?: number
  keywords?: string[]
  showTableOfContent?: boolean
}

export type ArticlePageContent = Omit<
  MappedApiPageContent<ApiArticlePageContent>,
  'updateDate'
> & {
  updateDate?: Date
}

export interface Category {
  title: string
  slug: string
  name?: string
}

export interface Author {
  id: string
  type: string
  hiddenDevices: string[]
  props: AuthorProps
}

type SocialIcon =
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'twitter'
  | 'medium'
  | 'link'
  | 'profile'
  | 'pinterest'
  | 'tiktok'
  | 'youtube'

export type SocialProfile = {
  id: string
  link: ContentLink
  icon: SocialIcon
}

export interface AuthorProps {
  technicalName?: string
  name: string
  role: string
  presentation: string
  image: Image
  profiles?: SocialProfile[]
}

export interface RelatedArticles {
  id: string
  type: string
  hiddenDevices: Device[]
  props: RelatedArticlesProps
}

// TODO COM-1345 Remove 'guides', make 'articles' required
export type RelatedArticlesProps = {
  title: string
} & ({ guides?: Article[] } | { articles?: Article[] })

export interface Article {
  id: number
  tagline: string
  title: string
  text: string
  link: ContentLink
  image: SimpleImage
  category: string
  translatedCategory: string
}

/* Schemas */
export const CategorySchema = z.object({
  title: z.string(),
  slug: z.string(),
  name: z.string().optional(),
})

export const SocialIconSchema = z.enum([
  'facebook',
  'instagram',
  'linkedin',
  'twitter',
  'medium',
  'link',
  'profile',
  'pinterest',
  'tiktok',
  'youtube',
])

export const SocialProfileSchema = z.object({
  id: z.string(),
  link: ContentLinkSchema,
  icon: SocialIconSchema,
})

export const AuthorPropsSchema = z.object({
  technicalName: z.string().optional(),
  name: z.string(),
  role: z.string(),
  presentation: z.string(),
  image: ImageSchema,
  profiles: z.array(SocialProfileSchema).optional(),
})

/* Schemas validation */
type TestAuthorProps = ExpectTrue<
  Equal<AuthorProps, z.infer<typeof AuthorPropsSchema>>
>
