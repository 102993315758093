/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import {
  type PressArticleContent,
  PressArticleContentSchema,
} from './press-article-content'

/* TypeScript */
export type PressCarouselProps = {
  technicalName?: string
  articles?: PressArticleContent[]
  title?: string
  subtitle?: string
}

/* Schemas */
export const PressCarouselPropsSchema = z.object({
  technicalName: z.string().optional(),
  articles: z.array(PressArticleContentSchema).optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
})

/* Schemas validation */
type TestPressCarouselProps = ExpectTrue<
  Equal<PressCarouselProps, z.infer<typeof PressCarouselPropsSchema>>
>
