/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type ReviewsProps = {
  technicalName?: string
  title: string
  subtitle?: string
  reviews_ids: string[]
}

/* Schemas */
export const ReviewsPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string(),
  subtitle: z.string().optional(),
  reviews_ids: z.array(z.string()),
})

/* Schemas validation */
type TestReviewsProps = ExpectTrue<
  Equal<ReviewsProps, z.infer<typeof ReviewsPropsSchema>>
>
