/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type Image, ImageSchema } from './image'

/* TypeScript */
export type ApiMonochromeBackgroundTheme =
  | 'purple'
  | 'coffee'
  | 'emerald'
  | 'blush'
  | 'cornflower'
  | 'bush'
  | 'tangaroa'
  | 'violet'
  | 'white'

export type ApiMonochromeBackground = { theme: ApiMonochromeBackgroundTheme }
export type ApiImageBackground = {
  image: Image
  mode: 'light' | 'dark'
}

export type ApiBackground = ApiImageBackground | ApiMonochromeBackground

/* Schemas */
const ApiMonochromeBackgroundThemeSchema = z.enum([
  'purple',
  'coffee',
  'emerald',
  'blush',
  'cornflower',
  'bush',
  'tangaroa',
  'violet',
  'white',
])

export const ApiMonochromeBackgroundSchema = z.object({
  theme: ApiMonochromeBackgroundThemeSchema,
})

export const ApiImageBackgroundSchema = z.object({
  image: ImageSchema,
  mode: z.enum(['light', 'dark']),
})

export const ApiBackgroundSchema = z.union([
  ApiImageBackgroundSchema,
  ApiMonochromeBackgroundSchema,
])

/* Schemas validation */
type TestApiBackground = ExpectTrue<
  Equal<ApiBackground, z.infer<typeof ApiBackgroundSchema>>
>
