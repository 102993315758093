<template>
  <RevInfoBlock
    class="max-h-[450px] overflow-scroll rounded-none"
    data-test="cms-context-info-block"
    :dismissable="true"
    :icon="IconAlert"
    :title="i18n(translations.contextTitle)"
    :variant
  >
    <div class="flex items-center justify-between">
      <p v-if="props.lastCacheRefresh">
        {{ i18n(translations.contextLastCacheUpdate) }}

        {{
          i18n.date(new Date(props.lastCacheRefresh), {
            timeStyle: 'medium',
            dateStyle: 'short',
          })
        }}
      </p>

      <div>
        <RevLink target="_blank" :to="DOCUMENTATION_URL">
          {{ i18n(translations.contextDocumentation) }}
        </RevLink>

        <RevButton
          v-if="cmsPageId"
          class="ml-12"
          :icon="IconExternalLink"
          size="small"
          target="_blank"
          :to="entryLinkGenerator(cmsPageId)"
          variant="primary"
        >
          {{ i18n(translations.contextOpenCMSPage) }}
        </RevButton>
      </div>
    </div>

    <div v-if="props?.errors?.length" class="bg-surface-default-low rounded-md">
      <RevAccordionItem :collapsed="accordionState" @click="onAccordionClick">
        <template #title>
          {{
            i18n(
              accordionState === 'expanded'
                ? translations.collapseContextErrors
                : translations.expandContextErrors,
            )
          }}
        </template>

        <template #body>
          <RevTable
            v-if="errors?.length"
            class="bg-surface-default-low"
            :collection="errors"
            :columns="tableColumns"
            transparent-header
          >
            <template #body-block-name="{ item }">
              {{ item.blockName }}
            </template>

            <template #body-block-info="{ item }">
              <div class="flex flex-col">
                <p
                  v-if="item.blockId && item.blockId !== item.entryId"
                  class="text-nowrap"
                >
                  {{
                    i18n(translations.contextTableBlockId, {
                      blockId: item.blockId,
                    })
                  }}
                </p>

                <p v-if="item.entryId" class="text-nowrap">
                  {{
                    i18n(translations.contextTableEntryId, {
                      entryId: item.entryId,
                    })
                  }}
                </p>
              </div>
            </template>

            <template #body-error="{ item }">
              {{ item.message }}
            </template>

            <template #body-actions="{ item }">
              <div class="flex">
                <RevButton
                  class="mr-4"
                  :disabled="isScrollDisabled(item.blockId)"
                  :icon="IconEyeOpen"
                  size="small"
                  variant="secondary"
                  @click="onScrollToBlock(item.blockId)"
                >
                  {{ i18n(translations.contextTableScroll) }}
                </RevButton>

                <RevButton
                  :disabled="isCMSLinkDisabled(item.entryId || item.blockId)"
                  :icon="IconExternalLink"
                  size="small"
                  target="_blank"
                  :to="entryLinkGenerator(item.entryId || item.blockId)"
                  variant="primary"
                >
                  {{ i18n(translations.contextTableOpen) }}
                </RevButton>
              </div>
            </template>
          </RevTable>
        </template>
      </RevAccordionItem>
    </div>
  </RevInfoBlock>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed, ref } from 'vue'

import type { Context } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import type { Maybe } from '@backmarket/utils/types/Maybe'
import {
  type CollapsedStates,
  RevAccordionItem,
} from '@ds/components/AccordionItem'
import { RevButton } from '@ds/components/Button'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { RevTable } from '@ds/components/Table'
import { IconAlert } from '@ds/icons/IconAlert'
import { IconExternalLink } from '@ds/icons/IconExternalLink'
import { IconEyeOpen } from '@ds/icons/IconEyeOpen'

import translations from './CMSContext.translations'

const props = withDefaults(defineProps<Context>(), {
  errors: () => [],
  cmsPageId: undefined,
})

const { CONTENTFUL_SPACE_ID } = useRuntimeConfig().public
const i18n = useI18n()

const accordionState = ref<CollapsedStates>('collapsed')

const variant = computed(() => {
  return props?.errors?.length ? 'danger' : 'success'
})

const tableColumns = [
  {
    key: 'block-name',
    label: i18n(translations.contextTableName),
  },
  {
    key: 'block-info',
    label: i18n(translations.contextTableInfo),
  },
  {
    key: 'error',
    label: i18n(translations.contextTableError),
  },
  {
    key: 'actions',
    label: i18n(translations.contextTableActions),
  },
]

const DOCUMENTATION_URL = 'https://backmarket.atlassian.net/l/cp/i81CxgTP'

function onAccordionClick() {
  accordionState.value =
    accordionState.value === 'collapsed' ? 'expanded' : 'collapsed'
}

function isCMSLinkDisabled(entryId: Maybe<string>) {
  if (!CONTENTFUL_SPACE_ID) return true
  if (!entryId) return true

  return false
}

function entryLinkGenerator(entryId: Maybe<string>) {
  if (isCMSLinkDisabled(entryId)) return undefined

  return `https://app.contentful.com/spaces/${CONTENTFUL_SPACE_ID}/entries/${entryId}`
}

function isScrollDisabled(blockId: Maybe<string>) {
  if (!blockId) return true

  return !document.getElementById(blockId)
}

function onScrollToBlock(blockId: Maybe<string>) {
  if (!blockId) return

  const blockElement = document.getElementById(blockId)
  if (blockElement) {
    blockElement.scrollIntoView({ behavior: 'instant', block: 'start' })
  }
}
</script>
