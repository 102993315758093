/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type TrustpilotWidgetProps = {
  technicalName?: string
  title?: string
  subtitle?: string
}

/* Schemas */
export const TrustpilotWidgetPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
})

/* Schemas validation */
type TestTrustpilotWidgetProps = ExpectTrue<
  Equal<TrustpilotWidgetProps, z.infer<typeof TrustpilotWidgetPropsSchema>>
>
