/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import {
  type ContentLink,
  ContentLinkWithTargetBlankSchema,
} from '@backmarket/http-api/src/api-specs-content/models/content-link'

import {
  LooseRichTextSchema,
  type LoosenRichTextFields,
} from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type Image, ImageSchema } from './image'
import { type RichText } from './rich-text'
import { type Tracking, TrackingSchema } from './tracking'

/* TypeScript */
export type MediaProps = {
  technicalName?: string
  image?: Image
  link?: ContentLink & { targetBlank?: boolean }
  subtitle?: string
  title?: string
  tracking?: Tracking
  rounded?: boolean
  caption?: RichText
  ignoreLinks?: boolean
}

/* Schemas */
export const MediaPropsSchema = z.object({
  technicalName: z.string().optional(),
  image: ImageSchema.optional(),
  link: ContentLinkWithTargetBlankSchema.optional(),
  subtitle: z.string().optional(),
  title: z.string().optional(),
  tracking: TrackingSchema.optional(),
  rounded: z.boolean().optional(),
  caption: LooseRichTextSchema.optional(),
  ignoreLinks: z.boolean().optional(),
})

/* Schemas validation */
type TestRichTextProps = ExpectTrue<
  Equal<LoosenRichTextFields<MediaProps>, z.infer<typeof MediaPropsSchema>>
>
