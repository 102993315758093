/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type ProductQuery = {
  apiKey: string
  indexName: string
  params: Record<string, unknown>
}

export type ProductQueries = ProductQuery[]

/* Schemas */
export const ProductQuerySchema = z.object({
  apiKey: z.string(),
  indexName: z.string(),
  params: z.record(z.unknown()),
})

export const ProductQueriesSchema = z.array(ProductQuerySchema)

/* Schemas validation */
type TestProductQueries = ExpectTrue<
  Equal<ProductQueries, z.infer<typeof ProductQueriesSchema>>
>
