/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import {
  type ApiImageBackground,
  ApiImageBackgroundSchema,
  type ApiMonochromeBackground,
  ApiMonochromeBackgroundSchema,
} from './background'
import { type CmsButtonProps, CmsButtonPropsSchema } from './cms-button'
import {
  type RelatedLinkProps,
  RelatedLinkPropsSchema,
} from './related-links-content'

/* TypeScript */
export type SectionBannerBackgroundApiProps = {
  sectionBannerImageBackground?: ApiImageBackground
  sectionBannerMonochromeBackground?: ApiMonochromeBackground
}

export type SectionBannerApiProps = {
  title: string
  technicalName?: string
  description?: string
  button?: CmsButtonProps
  relatedLinks?: RelatedLinkProps
  additionalSpacing?: 'Top' | 'Bottom' | 'Top-Bottom' | 'None'
} & SectionBannerBackgroundApiProps

/* Schemas */
export const SectionBannerBackgroundApiPropsSchema = z.object({
  sectionBannerImageBackground: ApiImageBackgroundSchema.optional(),
  sectionBannerMonochromeBackground: ApiMonochromeBackgroundSchema.optional(),
})

export const SectionBannerApiPropsSchema = z
  .object({
    title: z.string(),
    technicalName: z.string().optional(),
    description: z.string().optional(),
    button: CmsButtonPropsSchema.optional(),
    relatedLinks: RelatedLinkPropsSchema.optional(),
    additionalSpacing: z
      .enum(['Top', 'Bottom', 'Top-Bottom', 'None'])
      .optional(),
  })
  .and(SectionBannerBackgroundApiPropsSchema)

/* Schemas validation */
type TestSectionBannerApiProps = ExpectTrue<
  Equal<SectionBannerApiProps, z.infer<typeof SectionBannerApiPropsSchema>>
>
