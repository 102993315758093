/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'

type ButtonSize = 'tiny' | 'normal'
type ButtonVariant = 'primary' | 'secondary'

/* TypeScript */
export type CmsButtonProps = {
  technicalName?: string
  size: ButtonSize
  variant: ButtonVariant
  ctaLink: CTALink
}

/* Schemas */
const ButtonSizeSchema = z.enum(['tiny', 'normal'])
const ButtonVariantSchema = z.enum(['primary', 'secondary'])

export const CmsButtonPropsSchema = z.object({
  technicalName: z.string().optional(),
  size: ButtonSizeSchema,
  variant: ButtonVariantSchema,
  ctaLink: CTALinkSchema,
})

/* Schemas validation */
type TestCmsButtonProps = ExpectTrue<
  Equal<CmsButtonProps, z.infer<typeof CmsButtonPropsSchema>>
>
