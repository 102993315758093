/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'

/* TypeScript */
export type ButtonsProps = {
  technicalName?: string
  primary: CTALink
  secondary?: CTALink
  title?: string
  subtitle?: string
}

/* Schemas */
export const ButtonsPropsSchema = z.object({
  technicalName: z.string().optional(),
  primary: CTALinkSchema,
  secondary: CTALinkSchema.optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
})

/* Schemas validation */
type TestButtonsProps = ExpectTrue<
  Equal<ButtonsProps, z.infer<typeof ButtonsPropsSchema>>
>
