/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import {
  LooseRichTextSchema,
  type LoosenRichTextFields,
} from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type RichText } from './rich-text'
/* TypeScript */
export type QuizProfile = string

export type Answer = {
  id: string
  text: string
  profile: QuizProfile
}

export type Question = {
  question: string
  answers: Answer[]
}

export type Questions = Question[]

type ProfileResultPageMap = Record<QuizProfile, ContentLink>

export type QuizProps = {
  technicalName: string
  title: string
  description: RichText
  questions: Questions
  profileMapping: ProfileResultPageMap
}

/* Schemas */
export const QuizProfileSchema = z.string()

export const AnswerSchema = z.object({
  id: z.string(),
  text: z.string(),
  profile: QuizProfileSchema,
})

export const QuestionSchema = z.object({
  question: z.string(),
  answers: z.array(AnswerSchema),
})

export const QuestionsSchema = z.array(QuestionSchema)

export const ProfileResultPageMapSchema = z.record(
  QuizProfileSchema,
  ContentLinkSchema,
)

export const QuizPropsSchema = z.object({
  technicalName: z.string(),
  title: z.string(),
  description: LooseRichTextSchema,
  questions: QuestionsSchema,
  profileMapping: ProfileResultPageMapSchema,
})

/* Schemas validation */
type TestQuizProps = ExpectTrue<
  Equal<LoosenRichTextFields<QuizProps>, z.infer<typeof QuizPropsSchema>>
>
