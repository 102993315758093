/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'
import { type Image, ImageSchema } from './image'
import { type Tracking, TrackingSchema } from './tracking'
import { type CTAVideo, CTAVideoSchema } from './video'

export type ContentMediaProps = {
  technicalName?: string
  title?: string
  contentTitle: string
  text: string
  textPosition: 'left' | 'right'
  backgroundColor?: 'white'
  image: Image
  ctaLink?: CTALink
  ctaVideo?: CTAVideo
  tracking?: Tracking
  surtitle?: string
}

export const ContentMediaPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  contentTitle: z.string(),
  text: z.string(),
  textPosition: z.enum(['left', 'right']),
  backgroundColor: z.enum(['white']).optional(),
  image: ImageSchema,
  ctaLink: CTALinkSchema.optional(),
  ctaVideo: CTAVideoSchema.optional(),
  tracking: TrackingSchema.optional(),
  surtitle: z.string().optional(),
})

/* Schemas validation */
type TestContentMediaProps = ExpectTrue<
  Equal<ContentMediaProps, z.infer<typeof ContentMediaPropsSchema>>
>
