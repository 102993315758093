/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Maybe } from '@backmarket/utils/types/Maybe'
import { z } from 'zod'

import { type CTAStyling, CTAStylingSchema } from '../shared/cta-styling'
import {
  LooseRichTextSchema,
  type LoosenRichTextFields,
} from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type Image, ImageSchema } from './image'
import { type RichText } from './rich-text'

/* TypeScript */
export type CarouselSlide = {
  id: string
  technicalName?: string
  image: Image
  link?: Maybe<ContentLink>
  linkLabel?: string
  title?: RichText
  fontType?: 'Ivar' | 'Duplet'
  titleFontSize?: 'S' | 'M' | 'L'
  alignment?: 'Left' | 'Center'
  subtitle?: RichText
  ctaStyling?: CTAStyling
}

export type CarouselProps = {
  technicalName?: string
  slides: CarouselSlide[]
  isFullWidth?: boolean
}

/* Schemas */
export const CarouselSlideSchema = z.object({
  id: z.string(),
  technicalName: z.string().optional(),
  image: ImageSchema,
  link: ContentLinkSchema.optional().nullable(),
  linkLabel: z.string().optional(),
  title: LooseRichTextSchema.optional(),
  fontType: z.enum(['Ivar', 'Duplet']).optional(),
  titleFontSize: z.enum(['S', 'M', 'L']).optional(),
  alignment: z.enum(['Left', 'Center']).optional(),
  subtitle: LooseRichTextSchema.optional(),
  ctaStyling: CTAStylingSchema.optional(),
})

export const CarouselPropsSchema = z.object({
  technicalName: z.string().optional(),
  slides: z.array(CarouselSlideSchema),
  isFullWidth: z.boolean().optional(),
})

/* Schemas validation */
type TestCarouselSlide = ExpectTrue<
  Equal<
    LoosenRichTextFields<CarouselSlide>,
    z.infer<typeof CarouselSlideSchema>
  >
>

type TestCarouselProps = ExpectTrue<
  Equal<
    LoosenRichTextFields<CarouselProps>,
    z.infer<typeof CarouselPropsSchema>
  >
>
