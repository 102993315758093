/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type Tracking = Record<string, string | number>

/* Schemas */
export const TrackingSchema = z.record(z.union([z.string(), z.number()]))

/* Schemas validation */
type TestTracking = ExpectTrue<Equal<Tracking, z.infer<typeof TrackingSchema>>>
