import { type ZodTypeAny, z } from 'zod'

import { DeviceSchema } from '../models/device'

export function createBlockSchema<
  Type extends string,
  PropsSchema extends ZodTypeAny,
>(type: Type, propsSchema: PropsSchema) {
  return z.object({
    id: z.string(),
    type: z.literal(type),
    hiddenDevices: z.array(DeviceSchema),
    props: propsSchema,
  })
}
