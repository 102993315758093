/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type Image, ImageSchema } from './image'

/* TypeScript */
export type DiscountBannerProps = {
  technicalName?: string
  discountType: 'military' | 'student'
  title: string
  description: string
  ctaLabel: string
  image: Image
}

/* Schemas */
export const DiscountBannerPropsSchema = z.object({
  technicalName: z.string().optional(),
  discountType: z.enum(['military', 'student']),
  title: z.string(),
  description: z.string(),
  ctaLabel: z.string(),
  image: ImageSchema,
})

/* Schemas validation */
type TestDiscountBannerProps = ExpectTrue<
  Equal<DiscountBannerProps, z.infer<typeof DiscountBannerPropsSchema>>
>
