/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'

/* TypeScript */
export type RecommendationsApiProps = {
  technicalName?: string
  widgetId: string
  title?: string
  subtitle?: string
  ctaLink?: CTALink
}

/* Schemas */
export const RecommendationsApiPropsSchema = z.object({
  technicalName: z.string().optional(),
  widgetId: z.string(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  ctaLink: CTALinkSchema.optional(),
})

/* Schemas validation */
type TestRecommendationsApiProps = ExpectTrue<
  Equal<RecommendationsApiProps, z.infer<typeof RecommendationsApiPropsSchema>>
>
