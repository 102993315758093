/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type CmsIcon, CmsIconSchema } from './icon'

/* TypeScript */
export type ReassuranceItem = {
  id: string
  title: string
  description?: string
  icon: CmsIcon['icon']
  iconAppearance?: CmsIcon
  link?: ContentLink
  linkPosition: 'title' | 'description'
}

export type ReassuranceTheme =
  | 'gray'
  | 'white'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'green'
  | 'blue'

export type ReassuranceProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  blocks: ReassuranceItem[]
  mobileScreenCols?: number
  hasBackground?: boolean
  theme?: ReassuranceTheme
}

/* Schemas */
export const ReassuranceItemSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional(),
  icon: CmsIconSchema.shape.icon,
  iconAppearance: CmsIconSchema.optional(),
  link: ContentLinkSchema.optional(),
  linkPosition: z.enum(['title', 'description']),
})

export const ReassuranceThemeSchema = z.enum([
  'gray',
  'white',
  'yellow',
  'orange',
  'red',
  'green',
  'blue',
])

export const ReassurancePropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  blocks: z.array(ReassuranceItemSchema),
  mobileScreenCols: z.number().optional(),
  hasBackground: z.boolean().optional(),
  theme: ReassuranceThemeSchema.optional(),
})

/* Schemas validation */
type TestReassuranceProps = ExpectTrue<
  Equal<ReassuranceProps, z.infer<typeof ReassurancePropsSchema>>
>
