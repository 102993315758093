/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'
import { type ProductQueries, ProductQueriesSchema } from './product-query'

/* TypeScript */
export type ProductsBlockApiProps = {
  technicalName?: string
  productQueries: ProductQueries
  title: string
  subtitle: string
  ctaLink: CTALink
}

/* Schemas */
export const ProductsBlockApiPropsSchema = z.object({
  technicalName: z.string().optional(),
  productQueries: ProductQueriesSchema,
  title: z.string(),
  subtitle: z.string(),
  ctaLink: CTALinkSchema,
})

/* Schemas validation */
type TestProductsBlockApiProps = ExpectTrue<
  Equal<ProductsBlockApiProps, z.infer<typeof ProductsBlockApiPropsSchema>>
>
