/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type Image, ImageSchema } from './image'

/* TypeScript */
export type PressArticleContent = {
  id: string
  text: string
  publicationDate: string
  link: ContentLink
  image: Image
}

/* Schemas */
export const PressArticleContentSchema = z.object({
  id: z.string(),
  text: z.string(),
  publicationDate: z.string(),
  link: ContentLinkSchema,
  image: ImageSchema,
})

/* Schemas validation */
type TestPressArticleContent = ExpectTrue<
  Equal<PressArticleContent, z.infer<typeof PressArticleContentSchema>>
>
