/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'
import { type ProductQuery, ProductQuerySchema } from './product-query'

export const RefinedProductsLayout = {
  SLIDER: 'slider',
  GRID: 'grid',
} as const

/* TypeScript */
export type RefinedProductsLayout =
  (typeof RefinedProductsLayout)[keyof typeof RefinedProductsLayout]

export type RefinedProductsApiProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  ctaLink?: CTALink
  productLayout: RefinedProductsLayout
  productQuery: ProductQuery
}

/* Schemas */
export const RefinedProductsLayoutSchema = z.enum([
  RefinedProductsLayout.SLIDER,
  RefinedProductsLayout.GRID,
])

export const RefinedProductsApiPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  ctaLink: CTALinkSchema.optional(),
  productLayout: RefinedProductsLayoutSchema,
  productQuery: ProductQuerySchema,
})

/* Schemas validation */
type TestRefinedProductsApiProps = ExpectTrue<
  Equal<RefinedProductsApiProps, z.infer<typeof RefinedProductsApiPropsSchema>>
>
