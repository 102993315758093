/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type CountdownProps = {
  technicalName?: string
  date: string
  tagline?: string
  title?: string
  text?: string
}

/* Schemas */
export const CountdownPropsSchema = z.object({
  technicalName: z.string().optional(),
  date: z.string(),
  tagline: z.string().optional(),
  title: z.string().optional(),
  text: z.string().optional(),
})

/* Schemas validation */
type TestCountdownProps = ExpectTrue<
  Equal<CountdownProps, z.infer<typeof CountdownPropsSchema>>
>
