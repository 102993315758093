/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type SocialMediaEmbedProps = {
  technicalName: string
  title?: string
  subtitle?: string
  identifiers: string[]
  socialMediaType: 'InstagramPost' | 'InstagramReel' | 'TikTokPost' | 'XPost'
  size: 'Small' | 'Medium' | 'Large'
}

/* Schemas */
export const SocialMediaEmbedPropsSchema = z.object({
  technicalName: z.string(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  identifiers: z.array(z.string()),
  socialMediaType: z.enum([
    'InstagramPost',
    'InstagramReel',
    'TikTokPost',
    'XPost',
  ]),
  size: z.enum(['Small', 'Medium', 'Large']),
})

/* Schemas validation */
type TestSocialMediaEmbedProps = ExpectTrue<
  Equal<SocialMediaEmbedProps, z.infer<typeof SocialMediaEmbedPropsSchema>>
>
