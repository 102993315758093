import {
  type AnimatedProductCheckpointsProps,
  AnimatedProductCheckpointsPropsSchema,
} from './animated-product-checkpoints'
import {
  type ArticleCardsList,
  ArticleCardsListSchema,
} from './article-cards-list'
import { type AuthorProps, AuthorPropsSchema } from './article-page-content'
import { type ButtonsProps, ButtonsPropsSchema } from './buttons'
import { type CarouselProps, CarouselPropsSchema } from './carousel-content'
import { type CategoriesProps, CategoriesPropsSchema } from './categories'
import {
  type CategoryTreeProps,
  CategoryTreePropsSchema,
} from './category-tree'
import { type CmsButtonProps, CmsButtonPropsSchema } from './cms-button'
import { type ContentProps, ContentPropsSchema } from './content'
import {
  type ContentMediaProps,
  ContentMediaPropsSchema,
} from './content-media'
import { type CountdownProps, CountdownPropsSchema } from './countdown'
import { type Device } from './device'
import {
  type DiscountBannerProps,
  DiscountBannerPropsSchema,
} from './discount-banner'
import {
  type EmailCollectorApiProps,
  EmailCollectorApiPropsSchema,
} from './email-collector'
import {
  type EnhancedCarouselProps,
  EnhancedCarouselPropsSchema,
} from './enhanced-product-carousel'
import { type FaqApiProps, FaqApiPropsSchema } from './faq'
import { type ListItem, ListItemSchema } from './list-item'
import { type MediaProps, MediaPropsSchema } from './media'
import {
  type ModularCardImageProps,
  ModularCardImagePropsSchema,
  type ModularCardsCarouselProps,
  ModularCardsCarouselPropsSchema,
} from './modular-card'
import {
  type PressCarouselProps,
  PressCarouselPropsSchema,
} from './press-carousel-content'
import {
  type ProductsBlockApiProps,
  ProductsBlockApiPropsSchema,
} from './products-block'
import { type QuizProps, QuizPropsSchema } from './quiz'
import {
  type RecommendationsApiProps,
  RecommendationsApiPropsSchema,
} from './recommendations'
import {
  type RefinedProductsApiProps,
  RefinedProductsApiPropsSchema,
} from './refined-products'
import { type ReassuranceProps, ReassurancePropsSchema } from './reinsurance'
import {
  type RelatedLinkProps,
  RelatedLinkPropsSchema,
} from './related-links-content'
import { type ReviewsProps, ReviewsPropsSchema } from './reviews'
import { type RichTextProps, RichTextPropsSchema } from './rich-text-content'
import {
  type SectionBannerApiProps,
  SectionBannerApiPropsSchema,
} from './section-banner'
import {
  type SocialMediaEmbedProps,
  SocialMediaEmbedPropsSchema,
} from './social-media-embed'
import {
  type TrustpilotWidgetProps,
  TrustpilotWidgetPropsSchema,
} from './trustpilot-widget'
import { type YoutubeVideo, YoutubeVideoSchema } from './video'
import { type VideosBlockProps, VideosBlockPropsSchema } from './videos-content'

export type BlockPropsMap = {
  'Article-author': AuthorProps
  AnimatedProductCheckpoints: AnimatedProductCheckpointsProps
  ArticleCardsList: ArticleCardsList
  BuyingGuides: ArticleCardsList
  Button: CmsButtonProps
  Buttons: ButtonsProps
  Carousel: CarouselProps
  Categories: CategoriesProps
  CategoryTree: CategoryTreeProps
  Content: ContentProps
  ContentMedia: ContentMediaProps
  Countdown: CountdownProps
  DiscountBanner: DiscountBannerProps
  EmbeddedYoutubeVideo: YoutubeVideo
  EnhancedCarousel: EnhancedCarouselProps
  EmailCollector: EmailCollectorApiProps
  Faq: FaqApiProps
  ListItem: ListItem
  Media: MediaProps
  ModularCardsCarousel: ModularCardsCarouselProps
  ModularCardImage: ModularCardImageProps
  RefinedProducts: RefinedProductsApiProps
  Recommendations: RecommendationsApiProps
  Quiz: QuizProps
  PressCarousel: PressCarouselProps
  Products: ProductsBlockApiProps
  Reinsurance: ReassuranceProps
  RelatedLinks: RelatedLinkProps
  Reviews: ReviewsProps
  RichText: RichTextProps
  SectionBanner: SectionBannerApiProps
  SocialMediaEmbed: SocialMediaEmbedProps
  TrustpilotWidget: TrustpilotWidgetProps
  VideosBlock: VideosBlockProps
}

export type BlockType = keyof BlockPropsMap

export type BlockTypeProps = BlockPropsMap[BlockType]

export type _Block<T extends BlockType = BlockType> = {
  id: string
  type: T
  hiddenDevices: Device[]
  props: BlockPropsMap[T]
}

export type Block = {
  [T in keyof BlockPropsMap]: _Block<T>
}[keyof BlockPropsMap]

/* Schemas */
export const BlockPropsMapSchema = {
  'Article-author': AuthorPropsSchema,
  AnimatedProductCheckpoints: AnimatedProductCheckpointsPropsSchema,
  ArticleCardsList: ArticleCardsListSchema,
  BuyingGuides: ArticleCardsListSchema,
  Button: CmsButtonPropsSchema,
  Buttons: ButtonsPropsSchema,
  Carousel: CarouselPropsSchema,
  Categories: CategoriesPropsSchema,
  CategoryTree: CategoryTreePropsSchema,
  Content: ContentPropsSchema,
  ContentMedia: ContentMediaPropsSchema,
  Countdown: CountdownPropsSchema,
  DiscountBanner: DiscountBannerPropsSchema,
  EmbeddedYoutubeVideo: YoutubeVideoSchema,
  EnhancedCarousel: EnhancedCarouselPropsSchema,
  EmailCollector: EmailCollectorApiPropsSchema,
  Faq: FaqApiPropsSchema,
  ListItem: ListItemSchema,
  Media: MediaPropsSchema,
  ModularCardsCarousel: ModularCardsCarouselPropsSchema,
  ModularCardImage: ModularCardImagePropsSchema,
  RefinedProducts: RefinedProductsApiPropsSchema,
  Recommendations: RecommendationsApiPropsSchema,
  Quiz: QuizPropsSchema,
  PressCarousel: PressCarouselPropsSchema,
  Products: ProductsBlockApiPropsSchema,
  Reinsurance: ReassurancePropsSchema,
  RelatedLinks: RelatedLinkPropsSchema,
  Reviews: ReviewsPropsSchema,
  RichText: RichTextPropsSchema,
  SectionBanner: SectionBannerApiPropsSchema,
  SocialMediaEmbed: SocialMediaEmbedPropsSchema,
  TrustpilotWidget: TrustpilotWidgetPropsSchema,
  VideosBlock: VideosBlockPropsSchema,
}
