/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type Image, ImageSchema } from './image'

/* TypeScript */
export type YoutubeVideo = {
  technicalName?: string
  youtubeId: string
  title?: string
  subtitle?: string
  size?: 'small' | 'medium'
  image?: Image
}

export type CTAVideo = {
  label: string
  title?: string
  id: string
}

export type VideoAssetFile = {
  contentType: string
  fileName: string
  url: string
  details: { size: number }
}

export type VideoAsset = {
  title: string
  description: string
  file: VideoAssetFile
}

export type Video = {
  id: string
  video: VideoAsset
  image: Image
}

/* Schemas */
export const YoutubeVideoSchema = z.object({
  technicalName: z.string().optional(),
  youtubeId: z.string(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  size: z.enum(['small', 'medium']).optional(),
  image: ImageSchema.optional(),
})

export const CTAVideoSchema = z.object({
  label: z.string(),
  title: z.string().optional(),
  id: z.string(),
})

export const VideoAssetFileSchema = z.object({
  contentType: z.string(),
  fileName: z.string(),
  url: z.string(),
  details: z.object({
    size: z.number(),
  }),
})

export const VideoAssetSchema = z.object({
  title: z.string(),
  description: z.string(),
  file: VideoAssetFileSchema,
})

export const VideoSchema = z.object({
  id: z.string(),
  video: VideoAssetSchema,
  image: ImageSchema,
})

/* Schemas validation */
type TestYoutubeVideo = ExpectTrue<
  Equal<YoutubeVideo, z.infer<typeof YoutubeVideoSchema>>
>
type TestCTAVideo = ExpectTrue<Equal<CTAVideo, z.infer<typeof CTAVideoSchema>>>
type TestVideo = ExpectTrue<Equal<Video, z.infer<typeof VideoSchema>>>
