/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type Image, ImageSchema } from './image'
import {
  type SectionBannerBackgroundApiProps,
  SectionBannerBackgroundApiPropsSchema,
} from './section-banner'

/* TypeScript */
export type EmailCollectorApiProps = {
  technicalName?: string
  image?: Image
  title: string
  text: string
  buttonLabel?: string
  checkboxLabel?: string
  pdf?: {
    url: string
    label: string
  }
  emailCollectorTerms?: {
    title: string
    text: string
    hide: boolean
  }
} & SectionBannerBackgroundApiProps

/* Schemas */
export const EmailCollectorApiPropsSchema = z
  .object({
    technicalName: z.string().optional(),
    image: ImageSchema.optional(),
    title: z.string(),
    text: z.string(),
    buttonLabel: z.string().optional(),
    checkboxLabel: z.string().optional(),
    pdf: z
      .object({
        url: z.string(),
        label: z.string(),
      })
      .optional(),
    emailCollectorTerms: z
      .object({
        title: z.string(),
        text: z.string(),
        hide: z.boolean(),
      })
      .optional(),
  })
  .and(SectionBannerBackgroundApiPropsSchema)

/* Schemas validation */
type TestEmailCollectorApiProps = ExpectTrue<
  Equal<EmailCollectorApiProps, z.infer<typeof EmailCollectorApiPropsSchema>>
>
