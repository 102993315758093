/* eslint-disable @typescript-eslint/no-unused-vars */
import { type Maybe } from '@backmarket/utils/types/Maybe'
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type Image, ImageSchema } from './image'
import { type Tracking, TrackingSchema } from './tracking'

/* TypeScript */
export type Category = {
  id: string
  title: string
  tagline?: string | null
  image: Image
  link?: Maybe<ContentLink>
}

export type CategoryWithLink = Category & { link: ContentLink }

export type CategoriesProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  categories: Category[]
  tracking?: Tracking
}

/* Schemas */
export const CategorySchema = z.object({
  id: z.string(),
  title: z.string(),
  tagline: z.string().nullable().optional(),
  image: ImageSchema,
  link: ContentLinkSchema.nullable().optional(),
})

export const CategoryWithLinkSchema = z
  .object({
    link: ContentLinkSchema,
  })
  .and(CategorySchema)

export const CategoriesPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  categories: z.array(CategorySchema),
  tracking: TrackingSchema.optional(),
})

/* Schemas validation */
type TestCategoriesProps = ExpectTrue<
  Equal<CategoriesProps, z.infer<typeof CategoriesPropsSchema>>
>
