/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CmsIcon, CmsIconSchema } from './icon'

/* TypeScript */
export type ListItem = {
  technicalName?: string
  icon: CmsIcon
  text?: string
}

/* Schemas */
export const ListItemSchema = z.object({
  technicalName: z.string().optional(),
  icon: CmsIconSchema,
  text: z.string().optional(),
})

/* Schemas validation */
type TestListItem = ExpectTrue<Equal<ListItem, z.infer<typeof ListItemSchema>>>
