/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type CTALink, CTALinkSchema } from './cta-link'
import {
  type Image,
  ImageSchema,
  type SimpleImage,
  SimpleImageSchema,
} from './image'

/* TypeScript */
export type Article = {
  id: string
  title: string
  translatedCategory: string
  text: string
  image: Image | SimpleImage
  link: ContentLink
  category: string
}

// TODO COM-1345 Remove 'guides', make 'articles' required
export type ArticleCardsList = {
  technicalName?: string
  title?: string
  subtitle?: string
  ctaLink?: CTALink
  layout?: 'carousel' | 'grid'
} & ({ guides?: Article[] } | { articles?: Article[] })

/* Schemas */
export const ArticleSchema = z.object({
  id: z.string(),
  title: z.string(),
  translatedCategory: z.string(),
  text: z.string(),
  image: z.union([ImageSchema, SimpleImageSchema]),
  link: ContentLinkSchema,
  category: z.string(),
})

export const ArticleCardsListSchema = z
  .object({
    technicalName: z.string().optional(),
    title: z.string().optional(),
    subtitle: z.string().optional(),
    ctaLink: CTALinkSchema.optional(),
    layout: z.enum(['carousel', 'grid']).optional(),
  })
  .and(
    z.union([
      z.object({ guides: z.array(ArticleSchema).optional() }),
      z.object({ articles: z.array(ArticleSchema).optional() }),
    ]),
  )

/* Schemas validation */
type TestArticleCardsList = ExpectTrue<
  Equal<ArticleCardsList, z.infer<typeof ArticleCardsListSchema>>
>
