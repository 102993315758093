/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import { createBlockSchema } from '../schemas/create-block-schema'
import type { Equal, ExpectTrue } from '../types/validation'

import type { _Block } from './block'
import { type CTALink, CTALinkSchema } from './cta-link'
import { type Image, ImageSchema } from './image'
import { RecommendationsApiPropsSchema } from './recommendations'
import { RefinedProductsApiPropsSchema } from './refined-products'

/* TypeScript */
type EnhancedCarouselItem = {
  id?: string
  thumbnail: Image
  thumbnailName: string
  recommendation: _Block<'Recommendations'> | _Block<'RefinedProducts'>
}

export type EnhancedCarouselProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  image?: Image
  carousels: Array<EnhancedCarouselItem>
  cta?: CTALink
}

/* Schemas */
const EnhancedCarouselItemSchema = z.object({
  id: z.string().optional(),
  thumbnail: ImageSchema,
  thumbnailName: z.string(),
  recommendation: z.union([
    createBlockSchema('Recommendations', RecommendationsApiPropsSchema),
    createBlockSchema('RefinedProducts', RefinedProductsApiPropsSchema),
  ]),
})

export const EnhancedCarouselPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  image: ImageSchema.optional(),
  carousels: EnhancedCarouselItemSchema.array(),
  cta: CTALinkSchema.optional(),
})

/* Schemas validation */
type TestEnhancedCarouselProps = ExpectTrue<
  Equal<EnhancedCarouselProps, z.infer<typeof EnhancedCarouselPropsSchema>>
>
