/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type CmsIcon, CmsIconSchema } from './icon'

/* TypeScript */
export type CategoryEntry = {
  id: string
  name: string
  link: ContentLink
  icon?: CmsIcon
  subCategories: SubCategory[]
}

export type SubCategory = Omit<CategoryEntry, 'subCategories'>

export type CategoryTreeProps = {
  technicalName?: string
  title: string
  categoryTree: CategoryEntry[]
}

/* Schemas */
const CategoryEntrySchema = z.object({
  id: z.string(),
  name: z.string(),
  link: ContentLinkSchema,
  icon: CmsIconSchema.optional(),
  // re-declared because "we can't use it before its declaration" warning loop ...
  subCategories: z
    .object({
      id: z.string(),
      name: z.string(),
      link: ContentLinkSchema,
      icon: CmsIconSchema.optional(),
    })
    .array(),
})

export const CategoryTreePropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string(),
  categoryTree: CategoryEntrySchema.array(),
})

/* Schemas validation */
type TestCategoryTreeProps = ExpectTrue<
  Equal<CategoryTreeProps, z.infer<typeof CategoryTreePropsSchema>>
>
