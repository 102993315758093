/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Maybe } from '@backmarket/utils/types/Maybe'
import { z } from 'zod'

import { createBlockSchema } from '../schemas/create-block-schema'
import { type LoosenRichTextFields } from '../types/loose-rich-text'
import type { Equal, ExpectTrue } from '../types/validation'

import { type ApiBackground, ApiBackgroundSchema } from './background'
import { type _Block } from './block'
import { ButtonsPropsSchema } from './buttons'
import { CmsButtonPropsSchema } from './cms-button'
import { type ContentLink, ContentLinkSchema } from './content-link'
import { type CTALink, CTALinkSchema } from './cta-link'
import { type Image, ImageSchema } from './image'
import { ListItemSchema } from './list-item'
import { MediaPropsSchema } from './media'
import { RichTextPropsSchema } from './rich-text-content'
/* TypeScript */
type ModularCardSize = 'small' | 'medium'

export type ModularCardsCarouselProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  size: ModularCardSize
  cards: ModularCard[]
  cardsHavePadding?: boolean
  layout?: 'grid' | 'carousel'
  mobileScreenColumns?: 1 | 2
  ctaLink?: CTALink
}

export type ModularCard = {
  technicalName?: string
  id: string
  sections: ModularCardSection[]
  size?: ModularCardSize
  background?: Maybe<ApiBackground>
  link?: ContentLink
}

export type ModularCardSection = {
  alignment?: ModularCardSectionAlignment
  slots: Array<
    | _Block<'Button'>
    | _Block<'Buttons'>
    | _Block<'Media'>
    | _Block<'ModularCardImage'>
    | _Block<'ListItem'>
    | _Block<'RichText'>
  >
}

export type ModularCardImageProps = {
  technicalName?: string
  image: Image
  link?: ContentLink
  rounded?: boolean
}

export type ModularCardSectionAlignment = 'center' | 'left'

/* Schemas */
export const ModularCardSizeSchema = z.enum(['small', 'medium'])
export const ModularCardSectionAlignmentSchema = z.enum(['center', 'left'])

export const ModularCardImagePropsSchema = z.object({
  technicalName: z.string().optional(),
  image: ImageSchema,
  link: ContentLinkSchema.optional(),
  rounded: z.boolean().optional(),
})

export const ModularCardSectionSchema = z.object({
  alignment: ModularCardSectionAlignmentSchema.optional(),
  slots: z
    .union([
      createBlockSchema('Button', CmsButtonPropsSchema),
      createBlockSchema('Buttons', ButtonsPropsSchema),
      createBlockSchema('Media', MediaPropsSchema),
      createBlockSchema('ModularCardImage', ModularCardImagePropsSchema),
      createBlockSchema('ListItem', ListItemSchema),
      createBlockSchema('RichText', RichTextPropsSchema),
    ])
    .array(),
})

export const ModularCardSchema = z.object({
  technicalName: z.string().optional(),
  id: z.string(),
  sections: z.array(ModularCardSectionSchema),
  size: ModularCardSizeSchema.optional(),
  background: ApiBackgroundSchema.nullable().optional(),
  link: ContentLinkSchema.optional(),
})

export const ModularCardsCarouselPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  size: ModularCardSizeSchema,
  cards: z.array(ModularCardSchema),
  cardsHavePadding: z.boolean().optional(),
  layout: z.enum(['grid', 'carousel']).optional(),
  mobileScreenColumns: z.union([z.literal(1), z.literal(2)]).optional(),
  ctaLink: CTALinkSchema.optional(),
})

/* Schemas validation */
type TestModularCardsCarouselProps = ExpectTrue<
  Equal<
    LoosenRichTextFields<ModularCardsCarouselProps>,
    z.infer<typeof ModularCardsCarouselPropsSchema>
  >
>
